import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)">


          <path d="M16140 20255 c-107 -11 -208 -28 -370 -65 -125 -28 -346 -112 -486
-185 -164 -84 -173 -90 -319 -194 -787 -562 -1166 -1523 -976 -2476 19 -93 60
-247 80 -298 5 -12 24 -60 42 -107 66 -170 174 -371 268 -500 86 -117 241
-310 249 -310 4 0 39 -31 77 -68 144 -137 246 -216 418 -321 82 -50 220 -121
236 -121 8 0 11 421 11 1629 0 1291 3 1631 13 1638 12 9 35 14 250 48 53 8
141 22 195 30 53 9 129 20 167 26 39 6 92 15 118 20 26 5 55 9 62 9 13 0 15
-93 15 -685 0 -563 2 -687 13 -691 8 -3 34 1 58 9 44 14 102 27 213 47 77 13
340 13 425 -1 426 -68 642 -201 810 -498 122 -217 161 -439 161 -936 0 -181 4
-336 8 -343 6 -9 32 8 93 60 96 83 1844 1712 1854 1728 6 9 -77 102 -316 354
-47 50 -116 125 -154 166 -38 41 -105 114 -149 161 -45 47 -105 112 -135 145
-30 32 -91 97 -135 144 -45 47 -110 117 -145 155 -35 39 -98 106 -140 151 -42
44 -157 168 -256 274 -205 221 -253 270 -386 391 -299 272 -628 448 -1035 554
-256 67 -551 88 -834 60z m1025 -1290 c318 -51 411 -66 485 -76 41 -6 81 -13
88 -15 9 -3 12 -147 12 -681 0 -570 -2 -674 -13 -658 -8 11 -26 42 -42 68 -15
27 -62 83 -104 125 -114 114 -271 187 -446 208 -80 10 -104 10 -142 2 l-33 -7
0 529 c0 496 1 530 17 530 10 0 90 -11 178 -25z"/>
          <path d="M16510 16993 c-114 -5 -298 -43 -312 -65 -11 -19 -10 -1510 2 -1528
8 -13 42 -15 207 -15 109 1 238 7 288 14 120 17 311 58 337 72 20 11 20 14 15
537 -3 328 -9 544 -16 572 -6 25 -16 70 -23 100 -27 119 -110 233 -199 273
-71 31 -173 45 -299 40z"/>
          <path d="M13452 13950 c-259 -47 -411 -232 -412 -502 l0 -68 -110 0 -110 0 0
-155 0 -155 110 0 110 0 0 -465 0 -465 180 0 180 0 0 465 0 465 175 0 175 0 0
155 0 155 -176 0 -177 0 6 69 c11 137 69 196 192 195 50 0 84 -6 119 -22 27
-12 50 -22 52 -22 5 0 124 238 124 248 0 12 -115 67 -179 86 -69 20 -191 28
-259 16z"/>
          <path d="M9380 13035 l0 -895 193 2 192 3 3 353 2 352 400 0 400 0 0 -355 0
-355 193 2 192 3 3 893 2 892 -195 0 -195 0 0 -355 0 -355 -400 0 -400 0 0
355 0 355 -195 0 -195 0 0 -895z"/>
          <path d="M17440 13040 l0 -891 458 3 c520 5 533 6 707 92 275 136 426 397 442
761 17 396 -163 714 -480 847 -154 64 -206 70 -689 75 l-438 5 0 -892z m879
515 c167 -40 285 -162 327 -341 20 -84 14 -303 -9 -375 -40 -122 -116 -224
-205 -272 -89 -48 -147 -57 -384 -57 l-218 0 0 530 0 530 213 0 c154 0 230 -4
276 -15z"/>
          <path d="M21170 13721 l-135 -16 -5 -155 -5 -155 -107 -3 -108 -3 0 -144 0
-144 108 -3 107 -3 6 -310 c7 -331 11 -363 64 -471 33 -67 109 -136 183 -166
50 -20 75 -23 197 -23 147 0 271 22 282 51 3 8 -12 77 -33 154 -31 116 -40
140 -53 135 -39 -16 -131 -27 -164 -21 -51 10 -92 47 -106 98 -8 29 -11 125
-9 298 l3 255 158 3 157 3 0 144 0 145 -144 0 c-79 0 -151 3 -160 6 -14 5 -16
30 -16 175 l0 169 -42 -1 c-24 -1 -104 -9 -178 -18z"/>
          <path d="M14318 13430 c-197 -23 -346 -111 -407 -240 -23 -48 -26 -68 -26
-160 0 -91 3 -112 25 -155 65 -133 232 -221 455 -240 186 -15 245 -43 245
-116 0 -154 -354 -146 -577 14 -24 17 -45 28 -47 26 -14 -16 -146 -238 -146
-247 0 -25 140 -115 242 -157 199 -81 498 -76 669 10 178 91 258 295 193 497
-50 155 -200 236 -474 257 -180 14 -230 38 -230 113 0 49 32 81 95 97 110 28
236 2 376 -76 3 -2 45 47 94 108 l89 112 -30 24 c-88 72 -211 118 -354 133
-104 11 -97 11 -192 0z"/>
          <path d="M11715 13414 c-233 -48 -416 -227 -471 -459 -38 -162 -14 -376 58
-523 43 -88 150 -195 238 -240 232 -115 521 -90 670 59 l48 48 6 -39 c3 -22 9
-58 12 -80 l6 -40 161 2 162 3 3 628 2 627 -169 0 c-190 0 -181 4 -181 -81 0
-27 -3 -49 -6 -49 -4 0 -29 21 -56 46 -108 101 -289 138 -483 98z m361 -344
c109 -53 173 -164 174 -297 0 -137 -67 -244 -188 -301 -49 -23 -70 -27 -147
-27 -73 0 -100 5 -140 23 -63 29 -119 83 -153 147 -24 45 -27 60 -27 155 0 86
4 113 21 151 76 164 290 233 460 149z"/>
          <path d="M15655 13424 c-11 -2 -45 -9 -75 -15 -77 -15 -206 -79 -268 -135 -90
-79 -156 -193 -187 -326 -19 -81 -19 -262 0 -353 18 -86 80 -214 135 -277 97
-112 263 -187 432 -196 170 -8 307 36 399 128 l48 48 5 -27 c3 -14 8 -50 11
-78 l7 -53 164 0 164 0 0 630 0 630 -169 0 c-192 0 -181 6 -181 -94 l0 -50
-44 47 c-53 54 -122 91 -208 112 -58 13 -185 18 -233 9z m300 -349 c91 -43
158 -142 174 -255 21 -154 -48 -284 -188 -351 -38 -19 -63 -23 -141 -24 -84 0
-101 3 -152 28 -65 32 -121 89 -152 156 -17 37 -21 65 -21 146 0 89 3 106 27
150 67 128 187 191 341 180 33 -3 81 -16 112 -30z"/>
          <path d="M19745 13420 c-187 -28 -346 -129 -442 -281 -18 -30 -46 -90 -60
-134 -25 -73 -27 -94 -27 -235 0 -144 1 -160 27 -235 88 -250 284 -396 555
-412 161 -9 303 37 394 128 26 26 49 45 52 41 2 -4 7 -37 11 -74 l7 -68 164 0
164 0 0 625 0 625 -169 0 -170 0 -7 -70 -7 -70 -58 54 c-103 96 -255 133 -434
106z m310 -343 c155 -79 226 -283 155 -444 -25 -58 -95 -130 -155 -161 -72
-37 -197 -43 -276 -13 -75 29 -141 88 -176 159 -25 51 -28 68 -28 157 0 85 4
107 24 147 44 90 130 158 224 178 75 16 177 6 232 -23z"/>
          <path d="M22405 13415 c-248 -46 -425 -207 -491 -446 -14 -48 -18 -99 -18
-204 1 -124 4 -149 27 -220 36 -110 90 -197 164 -265 106 -97 231 -146 398
-157 158 -10 320 47 401 141 38 44 39 43 51 -49 l8 -60 163 -3 162 -2 0 625 0
625 -167 -2 -168 -3 -3 -25 c-1 -14 -5 -44 -8 -68 l-5 -42 -45 46 c-101 103
-278 145 -469 109z m331 -338 c77 -39 140 -115 165 -199 52 -169 -13 -325
-166 -407 -44 -23 -61 -26 -150 -26 -89 0 -106 3 -157 28 -65 32 -121 89 -152
156 -17 37 -21 65 -21 141 0 82 4 102 26 150 30 61 62 99 117 135 91 60 244
70 338 22z"/>
        </g>



      </svg>
    </Box >
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
